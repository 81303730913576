/* Start of Custom CSS styles */
html {
    font-size: 16px;        /* Most browsers default to 16 but not some older ones */
    color: #212529;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.break-after {
    page-break-after: always;
}

.break-before {
    page-break-before: always;
}

/* Stepper styles */
.stepper-button {
    font-weight: bold;
}

/* Font Awesome styles */
.red-times-circle {
    --fa-primary-color: white;
    --fa-secondary-color: red;
    --fa-secondary-opacity: 1.0;
}

.fa-opacity-100 {
    --fa-primary-opactiy: 1.0;
    --fa-secondary-opacity: 1.0;
}

/* Multi Select styles */
div.open-options-above div.dropdown-container[aria-expanded="true"] div.dropdown-content {
    position: relative !important;
    bottom: 344px;    
    height: 0;
    padding: 0;
}

/* Card styles */
div.vehicle-registration-card {
    overflow: visible !important;
}

.watermark {
    color: lightgray;
    font-size: 140px;
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    position: absolute;
    width: 100%;
    text-align: center;
    top: 35%;
    left: 0%;
    z-index: 1;
    opacity: 0.3;
}

div.letter-head hr {
    border-top-color: #62bb46;
}

.notes-character-count {
    font-size: 75%;
}

.inprogress-action-button::after, .action-button::after {   
    content: none;    
}

/* react-bootstrap-table responsive tables fix */
.react-bootstrap-table table {
  table-layout: auto !important;
}

/* Date Picker styles*/
.DateInput #endDate, .DateInput #startDate {
    font-weight: 400;
    font-size: 16px;
    padding: 5px 15px;
}

.DateRangePicker, .DateRangePickerInput__withBorder {
    width: 100%;
    height: 40px;
    border-radius: 0.25rem!important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateRangePicker .DateInput {
    width: 46%;
}

.DateRangePicker .DateInput #endDate {
    text-align: right;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #007BFF !important;
    border: none !important;
    color: #FFF !important;
}

.CalendarDay__selected_span {
    background: rgba(0, 123, 255, 0.3) !important;
    border: none !important;
    color: #212529 !important;
}

.CalendarDay__today {
    background: none !important;
    border: 2px solid #007BFF !important;
    color: #000 !important;
}

.CalendarDay__selected_span:hover {
    background: rgba(0, 123, 255, 0.6) !important;
    color: #000 !important;
}