@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/* make the customizations */

// Flags
// offsetPaddingWithBorderWidth - Set to true if you want components (buttons, form inputs, pagination) to have padding adjusted for border width so sum of border width and padding is divisible by 8. This means that x and y padding may not equal units of 8.  Set to false if you want padding inside components to be divisible by 8 regardless of border width.
$offsetPaddingWithBorderWidth:              true;       


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:                   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1152px,
  xxl: 1344px,
  xxxl: 1536px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:                         24px;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:                        1rem;
$table-cell-padding-sm:                     0.5rem;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

@if ($offsetPaddingWithBorderWidth) {
    $input-btn-padding-y:                   subtract(8px, $input-btn-border-width);
    $input-btn-padding-y-sm:                subtract(4px, $input-btn-border-width);
    $input-btn-padding-y-lg:                subtract(8px, $input-btn-border-width);
    $input-btn-padding-x:                   subtract(24px, $input-btn-border-width);
    $input-btn-padding-x-sm:                subtract(8px, $input-btn-border-width);
    $input-btn-padding-x-lg:                subtract(16px, $input-btn-border-width);
} @else {
    $input-btn-padding-y:                   8px;
    $input-btn-padding-y-sm:                4px;
    $input-btn-padding-y-lg:                8px;
    $input-btn-padding-x:                   24px;
    $input-btn-padding-x-sm:                8px;
    $input-btn-padding-x-lg:                16px;
}

$input-btn-line-height:                     24px;
$input-btn-line-height-sm:                  24px;
$input-btn-line-height-lg:                  32px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
$h1-line-height:                            48px;
$h2-line-height:                            40px;
$h3-line-height:                            32px;
$h4-line-height:                            32px;
$h5-line-height:                            24px;
$h6-line-height:                            24px;
$display1-line-height:                      120px;
$display2-line-height:                      112px;
$display3-line-height:                      96px;
$display4-line-height:                      72px;
$small-line-height:                         24px;
$lead-line-height:                          32px;
$blockquote-line-height:                    32px;
$blockquote-footer-line-height:             24px;
$headings-font-weight:                      600;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// Need to put these in so it grabs the above Buttons + Forms padding modifications
$btn-padding-y:                             $input-btn-padding-y;       
$btn-padding-x:                             $input-btn-padding-x;  
$btn-line-height:                           $input-btn-line-height;
$btn-padding-y-sm:                          $input-btn-padding-y-sm;
$btn-padding-x-sm:                          $input-btn-padding-x-sm;
$btn-line-height-sm:                        $input-btn-line-height-sm;
$btn-padding-y-lg:                          $input-btn-padding-y-lg;
$btn-padding-x-lg:                          $input-btn-padding-x-lg;
$btn-line-height-lg:                        $input-btn-line-height-lg;


// Forms

$input-padding-y:                           $input-btn-padding-y;
$input-padding-x:                           $input-btn-padding-x;
$input-line-height:                         $input-btn-line-height;

$input-padding-y-sm:                        $input-btn-padding-y-sm;
$input-padding-x-sm:                        $input-btn-padding-x-sm;
$input-line-height-sm:                      $input-btn-line-height-sm;

$input-padding-y-lg:                        $input-btn-padding-y-lg;
$input-padding-x-lg:                        $input-btn-padding-x-lg;
$input-line-height-lg:                      $input-btn-line-height-lg;

$input-height:                              add($input-line-height, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                           add($input-line-height-sm, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                           add($input-line-height-lg, add($input-padding-y-lg * 2, $input-height-border, false));

$form-check-input-gutter:                   1.5rem;
$form-check-input-margin-y:                 0.375rem;
$form-check-inline-margin-x:                1rem;
$form-check-inline-input-margin-x:          0.5rem;

$form-grid-gutter-width:                    24px;

// Navbar
$navbar-brand-padding-y:                    0;

// Pagination

@if ($offsetPaddingWithBorderWidth) {
    $pagination-padding-y:                  subtract(8px, $pagination-border-width);
    $pagination-padding-x:                  subtract(16px, $pagination-border-width);
    $pagination-padding-y-sm:               subtract(4px, $pagination-border-width);
    $pagination-padding-x-sm:               subtract(8px, $pagination-border-width);
    $pagination-padding-y-lg:               subtract(8px, $pagination-border-width);
    $pagination-padding-x-lg:               subtract(24px, $pagination-border-width);
} @else {
    $pagination-padding-y:                  8px;
    $pagination-padding-x:                  16px;
    $pagination-padding-y-sm:               4px;
    $pagination-padding-x-sm:               8px;
    $pagination-padding-y-lg:               8px;
    $pagination-padding-x-lg:               24px;
}

$pagination-line-height:                    24px;
$pagination-line-height-sm:                 24px;
$pagination-line-height-lg:                 32px;


// Cards

$card-spacer-y:                             0.5rem;
$card-spacer-x:                             1rem;
$card-group-margin:                         $grid-gutter-width / 2;     // reapply updated gutter width
$card-deck-margin:                          $card-group-margin;         
$card-columns-gap:                          1.5rem;
$card-columns-margin:                       1rem;


// Tooltips

// Popovers
$popover-header-padding-x:                  1rem;
$popover-body-padding-x:                    $popover-header-padding-x;


// Badges

$badge-padding-y:                           0.25rem;
$badge-padding-x:                           0.5rem;
$badge-pill-padding-x:                      0.5rem;


// Alerts
//
// Define alert colors, border radius, and padding.

@if ($offsetPaddingWithBorderWidth) {
    $alert-padding-y:                       subtract(1rem, .0625rem);
    $alert-padding-x:                       subtract(1rem, .0625rem);  // alert-padding-x has to be in rem units
} @else {
    $alert-padding-y:                       1rem;
    $alert-padding-x:                       1rem;   // alert-padding-x has to be in rem units
}


// List group

$list-group-item-padding-y:                 0.5rem;
$list-group-item-padding-x:                 1rem;


// Carousel

$carousel-control-width:                    72px;
$carousel-indicator-width:                  32px;
$carousel-indicator-height:                 4px;
$carousel-indicator-hit-area-height:        8px;
$carousel-indicator-spacer:                 4px;
$carousel-control-icon-width:               24px;
$carousel-caption-padding-y:                24px;
$carousel-caption-position-bottom:          16px;


