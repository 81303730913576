@use "variables";

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
            width: $container-max-width;
        }
    }
}

// Typography
h1, .h1 {
    line-height: $h1-line-height;
}

h2, .h2 {
    line-height: $h2-line-height;
}

h3, .h3 {
    line-height: $h3-line-height;
}

h4, .h4 {
    line-height: $h4-line-height;
}

h5, .h5 {
    line-height: $h5-line-height;
}

h6, .h6 {
    line-height: $h6-line-height;
}

.display-1 {
    line-height: $display1-line-height;      
}

.display-2 {
    line-height: $display2-line-height;
}

.display-3 {
    line-height: $display3-line-height;
}

.display-4 {
    line-height: $display4-line-height;
}

small, .small {
    line-height: $small-line-height;
}

.lead {
    line-height: $lead-line-height;
}

.blockquote {
    line-height: $blockquote-line-height;
}

.blockquote-footer {
    line-height: $blockquote-footer-line-height;
}


// Pagination
.page-link {
    line-height: $pagination-line-height;
}

.pagination-sm .page-link {
    line-height: $pagination-line-height-sm;
}

.pagination-lg .page-link {
    line-height: $pagination-line-height-lg;
}


// Modal
h1.modal-title {
    line-height: $h1-line-height;
}

h2.modal-title {
    line-height: $h2-line-height;
}

h3.modal-title {
    line-height: $h3-line-height;
}

h4.modal-title {
    line-height: $h4-line-height;
}

h5.modal-title {
    line-height: $h5-line-height;
}

h6.modal-title {
    line-height: $h6-line-height;
}


// Carousel
.carousel-caption {
  bottom: $carousel-caption-position-bottom;
  padding-top: $carousel-caption-padding-y;
  padding-bottom: $carousel-caption-padding-y;
}

// Custom ======================================================================
div.contentContainer {
    padding-bottom: 56px;
}

footer {
    position: fixed;
    bottom: 0;
    z-index: 1000;  // z-index on modals are > 1000
    width: 100%;
    background-color: white;
}

div.nav-sub-menu {
    // margin: 8px 0 0 0 !important;
}

// Bootstrap doesn't support multi-level sub menus
div.nav-sub-sub-menu {
    width: 100%;
    border: 0;
}

div.nav-sub-sub-menu .btn {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    text-align: left;
    border: 0;
    font-weight: 400;
    color: $dropdown-link-color;
    background-color: $dropdown-bg;
    border-radius: 0;
}

div.nav-sub-sub-menu .btn:hover {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
}

div.nav-sub-sub-menu .btn:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
}

div.nav-sub-sub-menu .btn:focus {
    text-decoration: none;
}

div.nav-sub-sub-menu .btn:disabled {
    color: $dropdown-link-disabled-color;
    text-decoration: none;
}

div.nav-sub-sub-menu .dropdown-menu.show{
    margin: -9px 0 0 0 !important;
}

button.initialButton {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: #ced4da;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #212529;
    text-decoration: none solid rgb(33, 37, 41);
    line-height: 14px;
    text-align: center;
    padding: 0;
    border: 0;
}

button.initialButton:after {
    display: none;      // Hides arrow caret on dropdown toggle button
}

.add-order-to-batch-modal {
    @include make-max-widths-container-width();
}

// .vin-search-modal {
//     @include make-max-widths-container-width();
// }