/* override styles when printing */
@media print {
    html {
        /* font-size: 18px; */      /* Uncomment if you want larger font in the print outs. */
        color: black;
    }

    div.results-accordion {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .vehicle-info-summary {
        border: 0px;
    }

    .vehicle-info-summary-body {
        display: block !important;
        height: auto;  
    }
}